<template>
  <section class="m-setting-clause m-scroll">
    <!-- 头部导航 -->
    <m-header title="Clause" ></m-header>
    <div class="main">
        <p style="line-height:normal">
            <strong><span class="f-s-24">Security Capabilities &amp; Policy for Transmission of Payment</span></strong>
        </p><br />
        <p>
            <strong><span>What information do we collect and how d­o we use it?</span></strong>
        </p>
        <p>
            <span>When you place an order through this website, we need to know your name, email, phone number, address and credit card details if paying by card. This information ­allows us to process and fulfill your or­der successfully. It also helps us in ma­intaining your account.</span>
        </p><br />
        <p>
            <strong><span>ow does MC International Enterprise ltd. protect customer information?</span></strong>
        </p>
        <p>
            <span>When you place orders, we use a secure server. The secure server software (SSL) encrypts all,information you input before it is sent to us. Furthermore, all of the sensitive customer data we,collect is protected by several layers of encryption and several layers of security to prevent unauthorized access.</span>
        </p><br />
        <p>
            <strong><span>Payment Details</span></strong>
        </p>
        <p>
            <span>Online payment service is provided by Bambora Ltd and SnapPay.&nbsp; Customers&#39; payment details are securely transmitted to the acquiring bank, card, and payment companies for real-time transaction authorization using 256-bit Extended Validation Certificates (EV) SSL transaction encryption. MC International Enterprise Ltd. does not st­ore any credit card information on our&nbsp; servers.</span>
        </p><br />
        <p>
            <strong><span>What About Cookies ?</span></strong>
        </p>
        <p>
            <span>&quot;Cookies&quot; are small pieces of information that are stored by your browser on your computers hard drive. Our cookies do not contain any personally identifying information. They allow you to place your online order and to login once you are subscribed. Most web brewers automatically accept cookies, but you can usually change your browser to prevent that. You do not need cookies enable to use the website. The Website uses &quot;session cookies&quot; which are only stored in your browser&#39;s memory for the length of time you are actually logged in.</span>
        </p><br />
        <p>
            <strong><span>Your Consent</span></strong>
        </p>
        <p>
            <span>y Using our website, you consent to the collection and use of this information by MC International Enterprise Ltd. If we decide to change our privacy policy we will post those changes on privacy policy page so that you are always aware of what information we collect, how we may use it, and under what circumstances we disclose it.</span>
        </p><br />
        <p>
            <strong><span>Payment Gateway Info­</span></strong>
        </p>
        <p>
            <span>Our payment options presently available­ are limited but, most importantly, safe­ and secure. We offer Credit Card paymen­ts for all our registered users. Credit ­Card payments are made directly through ­a SSL Encrypted software to ens­ure your personal details are kept safe­. None of your payment details are stored – this ensures you will be happy with the security of your transaction.</span>
        </p>
    </div>
  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'

export default {
  name:'Clause',
  components:{ MHeader },
  data(){
    return {
      
    }
  }
}
</script>

<style lang="less" scoped>
.m-setting-clause {
  width: 100%;
  height: 100%;
  background-color: #F5F9FF;
  padding-top:44px;
  overflow-y: auto;
  .main {
    margin-top: 12px;
    width: 100%;
    padding: 0 16px;
    font-size: 16px;
    .f-s-24{font-size:24px;font-family:'&#39;微软雅黑&#39;,&#39;sans-serif&#39';}
  }
}
</style>